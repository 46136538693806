@media screen and (max-height: 800px) {
    html {
        font-size: clamp(9px, 1vh, 11px);
    }

    #calls_by_interval .am5-root {
        font-size: clamp(5px, 1vh, 7px) !important;
    }

    #calls_by_interval .am5-legend-label {
        font-size: clamp(5px, 1vh, 7px) !important;
    }

    #calls_by_interval .am5-axis-label {
        font-size: clamp(5px, 1vh, 7px) !important;
    }
}

@media screen and (min-height: 801px) and (max-height: 1080px) {
    html {
        font-size: clamp(14px, 1vh, 16px);
    }
}

@media screen and (min-height: 1081px) and (max-height: 1439px) {
    html {
        font-size: clamp(16px, 1vh, 20px);
    }
}

@media screen and (min-height: 1440px) and (max-height: 2000px) {
    html {
        font-size: clamp(21px, 1vh, 24px);
    }
}

@media screen and (min-height: 2001px) {
    html {
        font-size: clamp(24px, 1vh, 28px);
    }
}

body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    box-sizing: border-box;

    --page-background: #ffffff;
    --section-background: #e6e6e6;
    --login-error: #660708;
    --login-button: #A4161A;
    --row-odd-background: #fafafa;
    --row-even-background: #d4d4d4;
    --header-height: 55px;
}

form {
    height: 100%;
}

#bytewise-oc-client {
    position: absolute;
    top: 0;
    z-index: 1300;
}

#bytewise-oc-dashboard {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    height: 100%;
    z-index: 100;
}

#modals {
    font-family: 'Open Sans', sans-serif;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


*,
*::before,
*::after {
    box-sizing: border-box;
}